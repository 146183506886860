.react-js-cron,
.react-js-cron .react-js-cron-field,
.react-js-cron > div {
  display: inline-block;
}

.react-js-cron .react-js-cron-field span {
  font-family: Inter;
  font-size: 14px !important;
}

.ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
  border-radius: 4px !important;
}
