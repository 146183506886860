/*
  Generic CSS classes that will be used in all components or pages
  Check generic css in the design https://www.figma.com/file/XLXIHrLbLeRq6csVCCJaCJ/Design-System?node-id=2%3A3
*/

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Inter.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/Inter.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/inter.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/inter.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Inter.ttf') format('truetype'); /* Safari, Android, iOS */
}

.shadow {
  box-shadow: 0px 8px 16px rgba(112, 144, 176, 0.12);
}

.custom-label {
  margin-bottom: 8px;
  color: #1e1f21;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-logo {
  width: 24px;
  margin-right: 8px;
  border-radius: 50%;
}

.item-bottom-border {
  border-bottom: 1px solid #ecf1f7;
}

.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

.background-grey {
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.1),
    inset 0px -1px 0px rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content-page-container {
  /* The height of the full page minus the header with "Back to" the data of the content and the footer */
  min-height: calc(100vh - 168px);
  /* This is to fix a weird horizontal scroll behaviour that appears on some pages */
  width: 100%;
}

.va-middle {
  vertical-align: middle;
}

.flex-grow-1 {
  flex-grow: 1;
}
.full-height {
  height: 100%;
}

.display-grid {
  display: grid;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 16px;
}

.m-8 {
  margin: 40px;
}

.mx-1 {
  margin-right: 4px;
  margin-left: 4px;
}

.mx-8 {
  margin-right: 40px;
  margin-left: 40px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-7 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mt-0 {
  margin-top: 0px;
}
.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 32px;
}

.mt-8 {
  margin-top: 40px;
}

.mt-9 {
  margin-top: 48px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 12px;
}

.mr-6 {
  margin-right: 24px;
}

.mr-7 {
  margin-right: 32px;
}

.mr-8 {
  margin-right: 40px;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-7 {
  margin-bottom: 32px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mb-9 {
  margin-bottom: 48px;
}

.mb-10 {
  margin-bottom: 56px;
}

.mb-11 {
  margin-bottom: 64px;
}

.mb-13 {
  margin-bottom: 80px;
}

.ml-a {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-6 {
  margin-left: 24px;
}

.ml-8 {
  margin-left: 40px;
}

.ml-9 {
  margin-left: 48px;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 12px;
}

.p-4 {
  padding: 16px;
}

.p-6 {
  padding: 24px;
}

.p-7 {
  padding: 32px;
}

.p-8 {
  padding: 40px;
}

.p-9 {
  padding: 48px;
}

.px-1 {
  padding-right: 4px;
  padding-left: 4px;
}

.px-2 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-4 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-6 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-7 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-8 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-9 {
  padding-right: 48px;
  padding-left: 48px;
}

.px-11 {
  padding-right: 64px;
  padding-left: 64px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-7 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-8 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-9 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 20px;
}

.pr-6 {
  padding-right: 24px;
}

.pr-8 {
  padding-right: 40px;
}

.pr-9 {
  padding-right: 48px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pb-7 {
  padding-bottom: 32px;
}

.pb-8 {
  padding-bottom: 40px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-4 {
  padding-top: 16px;
}

.pt-6 {
  padding-top: 24px;
}

.pt-7 {
  padding-top: 32px;
}

.pt-9 {
  padding-top: 48px;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-2 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pl-8 {
  padding-left: 40px;
}

.pl-9 {
  padding-left: 48px;
}

.br-1 {
  border-radius: 4px;
}

.br-2 {
  border-radius: 8px;
}

.br-3 {
  border-radius: 12px;
}

.br-4 {
  border-radius: 16px;
}

.br-5 {
  border-radius: 20px;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-items-start {
  align-items: flex-start;
}

.flex-items-end {
  align-items: flex-end;
}

.flex-items-center {
  align-items: center;
}

.flex-items-baseline {
  align-items: baseline;
}

.flex-items-stretch {
  align-items: stretch;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.overflow-hidden {
  overflow: hidden;
}

.m-auto {
  margin: auto;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100% !important;
}

.h-100 {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}

.overflow-y {
  overflow-y: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.h-auto {
  height: auto !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-8 {
  padding-bottom: 40px;
}

.pt-0 {
  padding-top: 0;
}

.pt-6 {
  padding-top: 24px;
}

.title-case {
  text-transform: capitalize;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}
