.terminal {
  overflow: auto;
}

.terminal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

.terminal::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.1),
    inset 0px -1px 0px rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
